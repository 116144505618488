import React from "react";
import BaseLayout from "layouts/base-layout";
import "scss/main.scss";

export default function NotFound(props) {
  const settings = props.pageContext?.settings;

  return <BaseLayout data={settings}>
    <div>
      404 Not Found
    </div>
  </BaseLayout>;
}
